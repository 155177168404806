@use 'mixins';

.ant-btn {
  font-style: normal;
  font-weight: 500;
  font-size: var(--md-font-size);
  border-radius: var(--md--border-radius);
  height: var(--md-height);
  padding: 0 var(--md-padding);
  display: inline-flex;
  justify-content: center;
  align-items: center;
  text-wrap: wrap;
  line-height: 1;

  @include mixins.box-shadow;

  span,
  a {
    line-height: 1;
  }

  &:hover:not(
      .ant-btn-primary,
      .ant-btn-default,
      .ant-btn-dangerous,
      .ant-btn-text,
      .ant-btn-dashed,
      .ant-btn-link
    ) {
    border: 1px solid var(--secondary-color);
    color: var(--secondary-color);
  }

  &-primary {
    border-color: var(--primary-color);
    background-color: var(--primary-color);
    color: #fff;

    &:is(:hover, :active, :focus) {
      border-color: var(--secondary-color);
      background-color: var(--secondary-color);
    }
  }

  &-default {
    border: 1px solid var(--primary-color);
    color: var(--primary-color);

    &:is(:hover, :active, :focus) {
      border: 1px solid var(--primary-color);
      color: var(--primary-color);
      background-color: color-mix(in srgb, var(--primary-color), #fff 95%);
    }
  }

  &-dashed {
    &:is(:hover, :active, :focus) {
      border-color: var(--secondary-color);
      border-style: dashed;
      color: var(--secondary-color);
    }
  }

  &-dangerous,
  &-dangerous.ant-btn-primary {
    border: 1px solid var(--Fill-Error-strong);
    background: var(--Fill-Error-strong);
    color: #fff;

    @include mixins.box-shadow;

    &:is(:hover, :active, :focus) {
      background: var(--Fill-Error-hover);
      border: 1px solid var(--Fill-Error-hover);
      color: #fff;
    }
  }

  &-secondary,
  &-secondary.ant-btn-default {
    border-color: var(--secondary-color);
    color: var(--secondary-color);
  }

  &-text {
    box-shadow: none;

    span {
      color: var(--secondary-color);
    }

    &:is(:hover, :active, :focus) {
      border-color: transparent;

      span {
        color: var(--secondary-color);
      }
    }
  }

  &-link {
    color: var(--primary-color);

    &:is(:hover, :active, :focus) {
      color: var(--secondary-color);
    }
  }

  &-sm:not(.ant-btn-link) {
    font-size: var(--sm-font-size);

    &:not(.ant-btn-circle) {
      border-radius: var(--sm--border-radius);
    }

    height: var(--sm-height);
    padding: 0 var(--sm-padding);

    span {
      font-size: var(--sm-font-size);
    }

    &.ant-btn-icon-only {
      height: var(--sm-height);
      padding: var(--sm-padding);
      font-size: var(--sm-font-size);
      border-radius: var(--sm--border-radius);

      &:not(.ant-btn-icon-only) {
        width: auto;
      }

      .anticon {
        font-size: 16px;
      }
    }
  }

  &-lg {
    font-size: var(--lg-font-size);
    border-radius: var(--lg--border-radius);
    height: var(--lg-height);
    padding: 0 var(--lg-padding);

    span,
    a {
      font-size: var(--lg-font-size);
    }
  }

  &-icon-only {
    height: var(--md-height);
    width: auto;
    padding: var(--md-padding);
    font-size: 26px;

    .anticon {
      font-size: 18px;
    }

    &.ant-btn-sm {
      width: var(--btn-small-width);
    }
  }
}
