.ant-checkbox-wrapper {
  align-items: center;
  line-height: 0;
  font-size: var(--default-font-size);

  .ant-checkbox,
  .ant-checkbox-checked {
    .ant-checkbox-inner {
      top: -3px;
      width: var(--checkbox-size);
      height: var(--checkbox-size);
      border-radius: var(--checkbox-border-radius);

      &::after {
        width: 8px;
        height: 12px;
        transform: rotate(40deg) scale(1) translate(-50%, -55%);
      }
    }

    &::after {
      border-radius: var(--checkbox-border-radius);
    }

    &.ant-checkbox-indeterminate {
      .ant-checkbox-inner {
        &::after {
          width: 12px;
          height: 12px;
          transform: rotate(0) scale(1) translate(-50%, -50%);
        }
      }
    }
  }

  .ant-checkbox-checked:not(.ant-checkbox-disabled) {
    .ant-checkbox-inner {
      background-color: var(--primary-color);
      border-color: var(--primary-color);
    }
  }

  &.ant-col + .ant-checkbox-wrapper {
    margin-left: 0;
  }

  &:hover {
    .ant-checkbox,
    .ant-checkbox-checked {
      .ant-checkbox-inner {
        border-color: var(--secondary-color);
      }

      &::after {
        top: -3px;
      }
    }
  }
}

.ant-checkbox-group {
  &.vertical,
  &--vertical {
    .ant-checkbox-group-item {
      width: 100%;
    }
  }
}
