.ant-collapse {
  &-header {
    &-text {
      font-weight: 500;
    }
  }

  &-icon-position-end > &-item > &-header &-arrow {
    font-size: 16px;
    color: var(--Text-Weak);
  }

  &-ghost {
    & > .ant-collapse-item {
      border-bottom: 1px solid var(--Stroke-Weak);
      transition: all 0.3s ease-in-out;

      &:first-of-type {
        border-top: 1px solid var(--Stroke-Weak);
      }

      &:hover {
        background: var(--Fill-Weaker);
      }
    }
  }
}
