@use 'mixins';

* {
  outline: none;
}

// Typography
h1,
h2,
h3,
h4,
h5,
h6 {
  @include mixins.font-rendering;

  color: var(--Text-Strong);
  font-weight: 500;
}

h1 {
  font-size: var(--h1-font-size);
}

h4 {
  font-size: var(--h4-font-size);
}

h5 {
  font-size: var(--h5-font-size);
}

h6 {
  font-size: var(--h6-font-size);
}

.text-align-center {
  text-align: center;
}

.text-align-right {
  text-align: right;
  justify-content: flex-end;
}

p,
span,
label,
ul,
li,
b {
  @include mixins.font-rendering;

  font-size: var(--default-font-size);
}

input {
  @include mixins.font-rendering;
}

a {
  color: var(--primary-color);
  text-decoration: none;
  transition: all ease-in-out 0.25s;

  &:hover {
    color: var(--secondary-color);
  }
}

.gap-10 {
  display: inline-flex;
  gap: 10px;
}

.gap-16 {
  display: inline-flex;
  gap: 16px;
}

/* Alert Colors */
.info {
  background: var(--Fill-Information-weak);
}

.info-color {
  color: var(--secondary-color);

  .anticon {
    color: var(--secondary-color);
  }
}

.warning {
  background: #fffbe6;
}

.warning-color {
  color: var(--Orange-solid-1000);

  .anticon {
    color: var(--Orange-solid-1000);
  }
}

.alert {
  background: var(--Fill-Error-weak);
}

.alert-color {
  color: var(--Fill-Error-strong);

  .anticon {
    color: var(--Fill-Error-strong);
  }
}

.impersonator {
  background: hsla(202, 100%, 57%, 0.651);
}

/* /Alert Colors */

/* Set Ant Design's component overlay above the Google Translate z-index */
.cdk-overlay-container {
  z-index: 1001;
}

/*
  We need to increase Google's Place Autocomplete dropdown's z-index
  to the same value as the cdk-overlay-container to prevent issues
*/
.pac-container {
  z-index: 1001;
}

/* Tooltip */
.ant-tooltip {
  @include mixins.mobile {
    display: none;
  }
}
/* /Tooltip */

/* Update Notification */
.update-notification {
  background: #e6f4ff;

  .ant-notification-notice-close {
    display: none;
  }
}
/* /Update Notification */
