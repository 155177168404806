.ant-steps {
  &-item {
    &-finish &-icon {
      border-color: var(--primary-color);

      > .ant-steps-icon {
        color: var(--primary-color);
      }
    }

    &-finish > &-container > &-content > &-title::after {
      background-color: var(--primary-color);
    }
  }
}
