.ant-tabs {
  font-size: var(--md-font-size);

  &-top {
    .ant-tabs-nav {
      margin-bottom: var(--Spacing-32);

      .ant-tabs-ink-bar {
        height: 4px;
      }

      &::before {
        border-bottom: 4px solid var(--Stroke-Weak);
      }
    }
  }

  &-tab {
    &.ant-tabs-tab-active {
      .ant-tabs-tab-btn {
        color: var(--primary-color);

        a {
          &:hover {
            color: var(--primary-color);
          }
        }
      }
    }

    &:hover {
      color: var(--secondary-color);
    }
  }

  &.ant-tabs-large {
    & > .ant-tabs-nav {
      .ant-tabs-tab {
        padding: 16px 0 10px 0;

        .anticon {
          margin-right: 5px;
        }
      }
    }
  }

  &-ink-bar {
    background: var(--primary-color);
  }
}
