.ant-modal {
  &-mask {
    background: var(--Fill-Overlay);
    backdrop-filter: blur(1px);
  }

  &-header {
    border-radius: var(--modal-border-radius) var(--modal-border-radius) 0 0;

    .ant-modal-title {
      font-size: var(--h4-font-size);
      font-weight: 600;
    }
  }

  &-content {
    border-radius: var(--modal-border-radius);
  }

  &-footer {
    display: flex;
    gap: var(--md-padding);
    padding: var(--lg-padding) var(--xl-padding);

    .ant-btn + .ant-btn:not(.ant-dropdown-trigger) {
      margin: 0;
    }
  }

  &-header,
  &-body,
  &-footer {
    padding: var(--lg-padding) var(--xl-padding);
    border-color: var(--Stroke-Weak);
  }

  &-confirm {
    &-info {
      .anticon-info-circle {
        color: var(--primary-color);
      }
    }

    &-warning {
      .anticon-exclamation-circle {
        color: var(--Orange-solid-1000);
      }
    }
  }
}
